import { sanitize, nvl } from './util.js'
import messages from './lang/messages.js'

export {
    getPostings,
    getPostingsFilters,
    getPostingDetails,
    getSelfServiceURL,
    getValidationParameters,
    postApplyToPosting,
    postAddAttachment
}

// Returns a jQuery Promise object with an array of job postings as data
// The authentication token parameter is optional to fetch public postings
function getPostings (
    institution, jobSector, jobField, jobClass, commitment, location, search, language, token
) {
    var deferred = $.Deferred();

    $.ajax({
        url: manitou.config.restServiceUrl + manitou.config.serviceRoutes.postings + '/' +
            nvl(institution, '0') + '/' + 
            nvl(jobSector, '0') + '/' + 
            nvl(jobField, '0') + '/' + 
            nvl(jobClass, '0') + '/' + 
            nvl(commitment, '0') + '/' + 
            nvl(location, '0') + '/' + 
            sanitize(search + ' ') + '/' + 
            nvl(language, '') + 
            (token ? '/' + token : ''),
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            var result = [];

            $.each(data.items, function(index, rawPosting) {
                var posting = {
                    'id': nvl(rawPosting.ide_affch, ''),
                    'title': nvl(rawPosting.titre, ''),
                    'deadline': nvl(rawPosting.date_post, ''),
                    'location': nvl(rawPosting.lieu, ''),
                    'institution': nvl(rawPosting.etablissement, ''),
                    'sector': nvl(rawPosting.secteur, ''),
                    'commitment': nvl(rawPosting.engagement, ''),
                    'jobField': nvl(rawPosting.domaine_emploi, ''),
                    'jobClass': nvl(rawPosting.classe_emploi, ''),
                    'competitionNumber': nvl(rawPosting.num_concr, '')
                };
                result.push(posting);
            });

            deferred.resolve(result);
        },
        error: function() {
            deferred.reject(messages.errors.getPostings);
        }
    });

    return deferred.promise();
}

// Returns a jQuery Promise object with an array of job postings as data
function getPostingsFilters (language) {
    var deferred = $.Deferred();

    $.ajax({
        url: manitou.config.restServiceUrl + manitou.config.serviceRoutes.postingsFilters + '/' + nvl(language, ''),
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            // Translates REST API keys to internal service keys
            var KEY_MATCHING = {
                'division': 'institution', 
                'sector': 'jobSector', 
                'jobField': 'jobField', 
                'jobClass': 'jobClass', 
                'commitment': 'commitment', 
                'jobLocation': 'location'
            }

            var result = {
                'institution': [], 
                'jobSector': [], 
                'jobField': [], 
                'jobClass': [], 
                'commitment': [], 
                'location': []
            };

            $.each(data, function(filterType, rawFilters) {
                if (KEY_MATCHING[filterType]) {
                    $.each(rawFilters, function(index, rawFilter) {
                        var filter = {
                            'id': Number(rawFilter.value),
                            'value': rawFilter.title
                        };
                        result[KEY_MATCHING[filterType]].push(filter);
                    });
                }
            });

            deferred.resolve(result);
        },
        error: function() {
            deferred.reject(messages.errors.getPostingsFilters);
        }
    });

    return deferred.promise();
}

// Returns a jQuery Promise object with an object of the job posting details
function getPostingDetails (postingId, language) {
    var deferred = $.Deferred();

    $.ajax({
        url: manitou.config.restServiceUrl + manitou.config.serviceRoutes.postingDetails + '/' + postingId + '/' + nvl(language, ''),
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            // Translates REST API keys to internal service keys
            var KEY_MATCHING = {
                'header': 'contentHeader', 
                'body': 'contentBody', 
                'footer': 'contentFooter'
            }

            var result = {
                'id': postingId,
                'title': data.title,
                'logoURL': data.logoURL,
                'sharingURL': data.sharingURL,
                'facebookURL': data.facebookSharingURL,
                'twitterURL': data.twitterSharingURL,
                'linkedInURL': data.linkedInSharingUrl,
                'contentHeader': [],
                'contentBody': [],
                'contentFooter': []
            };

            $.each(data, function(key, value) {
                if (KEY_MATCHING[key]) {
                    $.each(value, function(index, content) {
                        var postingItem = {
                            'label': content.label,
                            'value': content.content
                        };
                        result[KEY_MATCHING[key]].push(postingItem);
                    });
                }
            });

            deferred.resolve(result);
        },
        error: function() {
            deferred.reject(messages.errors.getPostingDetails);
        }
    });

    return deferred.promise();
}

// Returns a jQuery Promise object with the self-service URL
function getSelfServiceURL (language) {
    var deferred = $.Deferred();

    $.ajax({
        url: manitou.config.restServiceUrl + manitou.config.serviceRoutes.selfServiceUrl + '/' + nvl(language, ''),
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            var result = {
                'url': data.ss_url
            };
            deferred.resolve(result);
        },
        error: function() {
            deferred.reject(messages.errors.getSelfServiceUrl);
        }
    });

    return deferred.promise();
}

// Returns a jQuery Promise object with the validation parameters for the job platform
function getValidationParameters (language) {
    var deferred = $.Deferred();

    $.ajax({
        url: manitou.config.restServiceUrl + manitou.config.serviceRoutes.validationParameters + '/' + nvl(language, ''),
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            var result = {
                'selfServiceIsActive': ((data.selfServiceIsActive ? data.selfServiceIsActive.toUpperCase() : '') == 'O' ? true : false),
                'maxFileSize': (data.maxFileSize ? data.maxFileSize : 10485760) , //10MB if for some reason the parameter maxFileSize is null
                'emailConfirmationIsActive': ((data.emailConfirmationIsActive ? data.emailConfirmationIsActive.toUpperCase() : '') == 'O' ? true : false),
            };
            deferred.resolve(result);
        },
        error: function() {
            deferred.reject(messages.errors.getValidationParameters);
        }
    });

    return deferred.promise();
}

// Returns a jQuery Promise object with an object of the response
function postApplyToPosting (
    firstName, lastName, email, phoneNumber, resumeFileName, resumeFile, postingId, comment, language
) {
    var deferred = $.Deferred();

    var applicationForm = new FormData();
    applicationForm.append('language', nvl(language, ''));
    applicationForm.append('firstName', nvl(firstName, ''));
    applicationForm.append('lastName', nvl(lastName, ''));
    applicationForm.append('email', nvl(email, ''));
    applicationForm.append('phoneNumber', nvl(phoneNumber, ''));
    applicationForm.append('postingID', nvl(postingId, ''));
    applicationForm.append('comment', nvl(comment, '').substring(0, 4000));
    applicationForm.append('applicantSource', (postingId ? manitou.config.applicantSource : manitou.config.spontaneousApplicantSource));
    applicationForm.append('applicationSource', manitou.config.applicationSource);
    applicationForm.append('filename', nvl(resumeFileName, ''));
    applicationForm.append('file', resumeFile);

    $.ajax({
        url: manitou.config.restServiceUrl + manitou.config.serviceRoutes.applyToPosting,
        method: 'POST',
        dataType: 'json',
        contentType: false,
        processData: false,
        data: applicationForm,
        success: function(data) {
            var result = {
                'status': data.status,
                'message': data.messages[0],
                'applicantId': data.applicantID,
                'applicationId': data.applicationID,
                'attachmentToken': data.attachmentToken
            };

            deferred.resolve(result);
        },
        error: function(data) {
            var result = {
                'errors': []
            };

            if (data.responseJSON) {
                $.each(data.responseJSON.errors, function(index, rawError) {
                    result.errors.push({
                        'parameter': rawError.parameter,
                        'message': rawError.message
                    });
                });
            } else {
                result.errors.push({
                    'parameter': 'other',
                    'message': messages.errors.applyToPosting
                });
            }

            deferred.reject(result);
        }
    });

    return deferred.promise();
}

// Returns a jQuery Promise object with an object of the response
function postAddAttachment (
    applicantId, applicationId, fileName, file, description, attachmentTypeId, isMainCV, language, tempToken
) {
    var MAX_FILE_DESCRIPTION_LENGTH = 127;
    var deferred = $.Deferred();

    var applicationForm = new FormData();
    applicationForm.append('language', nvl(language, ''));
    applicationForm.append('tempToken', nvl(tempToken, ''));
    applicationForm.append('ideCandt', nvl(applicantId, ''));
    applicationForm.append('ideAffchCandt', nvl(applicationId, ''));
    applicationForm.append('description', nvl(description, ' ').substr(0, MAX_FILE_DESCRIPTION_LENGTH));
    applicationForm.append('isMainCV', nvl(isMainCV, 'N'));
    applicationForm.append('attachmentTypeId', nvl(attachmentTypeId, ''));
    applicationForm.append('filename', nvl(fileName, ''));
    applicationForm.append('file', nvl(file, ''));

    $.ajax({
        url: manitou.config.restServiceUrl + manitou.config.serviceRoutes.addAttachment,
        method: 'POST',
        dataType: 'json',
        contentType: false,
        processData: false,
        data: applicationForm,
        success: function(data) {
            var result = {
                'attachmentId': data.id,
                'attachmentName': data.file_name,
                'creationDate': data.created_on
            };

            deferred.resolve(result);
        },
        error: function(data) {
            var result = {
                'errors': []
            };

            if (data.responseJSON) {
                $.each(data.responseJSON.errors, function(index, rawError) {
                    result.errors.push({
                        'message': rawError.message
                    });
                });
            } else {
                result.errors.push({
                    'parameter': 'other',
                    'message': messages.errors.addAttachment
                });
            }

            deferred.reject(result);
        }
    });

    return deferred.promise();
}