export { 
    sanitize,
    nvl,
    getQueryStringParameter,
    setQueryStringParameter,
    pushHistoryState 
}

function sanitize (inputString) {
    var filteredString = inputString.replace(/[?#\\|:*%<>";]/gi, '');
    var encodedString = encodeURIComponent(filteredString);
    return encodedString;
};

function nvl (val1, val2) {
    return (val1 === undefined || val1 === null) ? val2 : val1;
};

function pushHistoryState(url) {
    window.history.pushState(window.history.state, '', url);
};

function getQueryStringParameter(key) {
    key = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("([?&!])" + key + "=(.*?)(&|#|$|!)", "i");
    var results = regex.exec(decodeURI(window.location.href).replace("%3D", "="));
    if (results == null) {
        return "";
    } else {
        return results[2];
    }
}

function setQueryStringParameter(key, value, url) {
	var url = (url ? url : window.location.href);
    var regex = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
    var results = regex.exec(url.replace("%3D", "="));
    //Delete the query string parameter if value is null
    if( value === undefined ) {
        if (results != null) {
            if(results[1] == '?') {
                return url.replace(regex, '$1');
            } else {
                return url.replace(regex, '$2');
            }
        } else {
            return url;
        }
    } else {
        if (results != null) {
            return url.replace(regex, '$1' + key + "=" + value + '$2');
        } else {
            var hash =  '';
            if( url.indexOf('#') !== -1 ){
                hash = url.replace(/.*#/, '#');
                url = url.replace(/#.*/, '');
            }
            var separator = url.indexOf('?') !== -1 ? "&" : "?";
            return url + separator + key + "=" + value + hash;
        }
    }  
};