import { 
    nvl,
    getQueryStringParameter, 
    setQueryStringParameter,
    pushHistoryState  
} from './util.js';
import * as services from './services.js';
import * as templates from './templates.js';
import messages, { initMessages } from './lang/messages.js';
import './pagination.js';

export { init, displayPostingDetails, displayPostings, POSTING_ID_QUERY_STRING_KEY }

var validationParametersPromise;

const POSTING_ID_QUERY_STRING_KEY = "ide_poste";

const FORM_SELECT_OPTIONS = {
    dropdownOptions: {
        onCloseEnd: function(element) {
            var $inputEl = $(element);
            var $selectEl = $inputEl.parent().find('select');
            var $inputFieldEl = $(element).closest('.input-field');
            var $selectWrapperEl = $selectEl.closest('.select-wrapper');

            // Validate the required dropdown
            if ($selectEl.attr('required')) {
                if ($inputEl.val() === '') {
                    $selectWrapperEl.addClass('invalid');
                } else {
                    $selectWrapperEl.removeClass('invalid');
                }
            }

            // For the customs selection dropdown with an "Other" option, hide or show the manual 
            // "Other" input field
            if ($selectEl.hasClass('manitouSelectWithOtherOption')) {
                if ($inputEl.val().split(', ').includes(messages.defaultOptions.other)) {
                    $inputFieldEl.next('.manitouOtherField').show();
                } else {
                    $inputFieldEl.next('.manitouOtherField').hide();
                }
            }
        }
    }
};

function init(language, postingId) {
    language = language.toLowerCase();
    // Select the appropriate language messages
    initMessages(language);

    if (window.state) {
        var newState = window.state;
        newState.manLanguage = language;
        window.history.replaceState(newState, '', window.location.href);
    } else {
        window.history.replaceState({'manLanguage': language}, '', window.location.href);
    }

    $('#manitouGlobalContainer')
    .on('click', '.manitouPostingButton', function() {
        var postingId = $(this).data('posting-id');
        displayPostingDetails(postingId, language);
        return false;
    })
    .on('click', '.manitouApplyFiltersButton', function() {
        filterPostings(language);
        return false;
    })
    .on('click', '.manitouResetFiltersButton', function() {
        $('#manitouGlobalContainer .manitouFilter').val('0');
        filterPostings(language);
        return false;
    });

    validationParametersPromise = services.getValidationParameters(language)
    .fail(function (errorMessage) {
        displayCriticalError(errorMessage);
    });

    // Get the posting id query string in the URL
    var postingIdQueryString = getQueryStringParameter(POSTING_ID_QUERY_STRING_KEY);

    if (postingId) {
        // If the postingId is not null/undefined, display the detail page of that posting
        displayPostingDetails(postingId, language);
        document.getElementById('manitouGlobalContainer').scrollIntoView();
    } else if (postingIdQueryString != "") {
        // If the posting id query string is not empty/null, display the detail page of that posting
        displayPostingDetails(postingIdQueryString, language);
        document.getElementById('manitouGlobalContainer').scrollIntoView();
    } else {
        // If the postingId is null/undefined, display the list of postings
        displayPostings(0, 0, 0, 0, 0, 0, language);
    }
};

// var displayPostingDetails = function(postingId, language) {
function displayPostingDetails (postingId, language) {
    $('#manitouGlobalContainer').html(
        templates.preloader()
    );

    // Manage the history and posting id URL query string
    var postingIdQueryString = getQueryStringParameter(POSTING_ID_QUERY_STRING_KEY);
    if (postingIdQueryString != postingId) {
        pushHistoryState(
            setQueryStringParameter(POSTING_ID_QUERY_STRING_KEY, postingId)
        );
    }
        
    services.getPostingDetails(postingId, language)
    .done(function (data) {
        $('#manitouGlobalContainer').html(
            templates.postingDetailsPage(
                data,
                getExtraAttachments(manitou.config.postingApplicationAttachments, language),
                manitou.config.resumeAttachmentId,
                manitou.config.displayPostingApplicationComment,
                getPostingApplicationCustomSelectionLists(
                    manitou.config.customSelectionLists, language
                )
            )
        );

        $('#manitouGlobalContainer .manitouBackToPostingsButton').on('click', function() {
            displayPostings(0, 0, 0, 0, 0, 0, language);
            return false;
        });

        $('#manitouGlobalContainer #manitouApplyButton').on('click', function() {
            if (validateApplicationForm()) {
                applyToPosting(postingId, language);
            }
            return false;
        });

        $('#manitouGlobalContainer select').formSelect(FORM_SELECT_OPTIONS);
    })
    .fail(function (errorMessage) {
        displayCriticalError(errorMessage);
    });
};

var filterPostings = function(language) {
    displayPostings(
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=institution]').val(),
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=jobSector]').val(),
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=jobField]').val(),
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=jobClass]').val(),
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=commitment]').val(),
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=location]').val(),
        language);
};

var displayPostings = function(institution, jobSector, jobField, jobClass, commitment, location, language) {
    $('#manitouGlobalContainer').html(
        templates.preloader()
    );

    // Manage the history and posting id URL query string
    var postingIdQueryString = getQueryStringParameter(POSTING_ID_QUERY_STRING_KEY);
    if (postingIdQueryString != "") {
        pushHistoryState(
            setQueryStringParameter(POSTING_ID_QUERY_STRING_KEY) // This removes the posting id query string
        );
    }

    var isFiltered = 
        (nvl(institution, 0) != 0) || 
        (nvl(jobSector, 0) != 0) || 
        (nvl(jobField, 0) != 0) || 
        (nvl(jobClass, 0) != 0) || 
        (nvl(commitment, 0) != 0) || 
        (nvl(location, 0) != 0);

    $.when(
        services.getPostings(institution, jobSector, jobField, jobClass, commitment, location, '', language), 
        services.getPostingsFilters(language),
        services.getSelfServiceURL(language)
    )
    .done(function (postings, filters, selfService) {
        var displayedFilters = {};
        var displayedPostings = [];

        // Filter out the filters that are not in the filterTypes config
        $.each(manitou.config.filterTypes, function (index, filterType) {
            if (filters[filterType]) {
                displayedFilters[filterType] = filters[filterType];
            }
        });

        // If filterByDivision is true in the config, filter out the divisions (institutions) filters that are not 
        // in the activeDivisions config
        if (manitou.config.filterByDivision && displayedFilters.institution) {
            displayedFilters.institution = [];
            $.each(filters.institution, function (index, institution) {
                if (manitou.config.activeDivisions.indexOf(institution.id) != -1) {
                    displayedFilters.institution.push(institution);
                }
            });
        }

        // If filterByDivision is true in the config, filter out postings with divisions (institutions) that are not 
        // in the activeDivisions config
        if (manitou.config.filterByDivision && filters.institution) {
            var activeDivisionsNames = [];
            $.each(filters.institution, function (index, institution) {
                if (manitou.config.activeDivisions.indexOf(institution.id) != -1) {
                    activeDivisionsNames.push(institution.value);
                }
            });

            $.each(postings, function (index, posting) {
                if (activeDivisionsNames.indexOf(posting.institution) != -1) {
                    displayedPostings.push(posting);
                }
            });
        } else {
            displayedPostings = postings;
        }

        // Sort postings
        if (manitou.config.sortingColumn && manitou.config.sortingColumn !== '') {
            var col = manitou.config.sortingColumn;
            var order = (manitou.config.sortingOrder === 'desc'? -1 : 1);
            displayedPostings.sort((a,b) => (a[col] > b[col]) ? 1*order : ((b[col] > a[col]) ? -1*order : 0))
        }

        $('#manitouGlobalContainer').html(
            templates.postingsListPage(displayedPostings, displayedFilters, isFiltered, manitou.config.postingsColumns)
        );

        if (manitou.config.displaySelfServiceLink) {
            $('#manitouGlobalContainer #manitouSelfServiceContainer').html(
                templates.selfServiceLink(selfService.url)
            );
        }

        if (manitou.config.displaySpontaneousApplication) {
            $('#manitouGlobalContainer').append(
                templates.spontaneousApplicationPage(
                    getExtraAttachments(manitou.config.spontaneousApplicationAttachments, language),
                    manitou.config.resumeAttachmentId,
                    manitou.config.displaySpontaneousApplicationComment,
                    getSpontaneousApplicationCustomSelectionLists(
                        manitou.config.customSelectionLists, language
                    )
                )
            );

            $('#manitouGlobalContainer #manitouApplyButton').on('click', function() {
                if (validateApplicationForm()) {
                    applyToPosting('', language);
                }
                return false;
            });
        }

        $('#manitouGlobalContainer .manitouFilter[data-filter-type=institution]').val(institution);
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=jobSector]').val(jobSector);
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=jobField]').val(jobField);
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=jobClass]').val(jobClass);
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=commitment]').val(commitment);
        $('#manitouGlobalContainer .manitouFilter[data-filter-type=location]').val(location);

        $('#manitouGlobalContainer select').formSelect(FORM_SELECT_OPTIONS);
        $('#manitouGlobalContainer .collapsible').collapsible();
        $('#manitouGlobalContainer #manitouPostingsTable').manitouPagination({
            showItemCount: true,
            showItemRange: true,
            itemsPerPage: manitou.config.itemsPerPage,
            totalItemCountSelector: '#manitouGlobalContainer .manitouTotalResults',
            lowerItemCountSelector: '#manitouGlobalContainer .manitouLowerResult',
            upperItemCountSelector: '#manitouGlobalContainer .manitouUpperResult',
            paginationSelector: '#manitouGlobalContainer .pagination',
            nextTooltipText: messages.buttons.nextTooltip,
            prevTooltipText: messages.buttons.previousTooltip,
            activeColor: 'grey darken-3'
        });

        //Hide pagination when there is only one page of postings
        if (displayedPostings.length <= manitou.config.itemsPerPage) {
            $('.manitouPaginationContainer').html('');
        }
    })
    .fail(function (errorMessage) {
        displayCriticalError(errorMessage);
    });
};

var applyToPosting = function (postingId, language) {
    //Disable form while loading
    $('#manitouGlobalContainer #manitouApplicationForm').addClass('disabled');
    //Hide form and display preloader
    $('#manitouGlobalContainer #manitouApplicationForm form').hide();
    $('#manitouGlobalContainer #manitouApplicationForm').append(templates.preloader());

    var comment = '';
    // Loop over the custom select lists to add to the comment field
    $('#manitouGlobalContainer #manitouApplicationForm select.manitouSelectToComment').each(
        function (index, element) {
            var $element = $(element);
            var selectionString = $element.parent().find('input').val();
            var otherValue = '';
            
            if($element.hasClass('manitouSelectWithOtherOption')) {
                if (selectionString.split(', ').includes(messages.defaultOptions.other)) {
                    otherValue = ' (' + $element.closest('.input-field').next('.manitouOtherField')
                        .find('input').val() + ')';
                }
            }

            comment += $element.data('label') + ':\n' + 
                       selectionString + otherValue + '\n\n';
        }
    );

    // Add the actual comment field value if it is displayed and contains a comment
    var commentFieldValue = $('#manitouGlobalContainer #manitouComment').val();
    if (commentFieldValue && commentFieldValue !== '') {
        comment += messages.labels.comment + '\n' + commentFieldValue;
    }

    services.postApplyToPosting(
        $('#manitouGlobalContainer #manitouFirstName').val(),         //firstName
        $('#manitouGlobalContainer #manitouLastName').val(),          //lastName
        $('#manitouGlobalContainer #manitouEmail').val(),             //email
        $('#manitouGlobalContainer #manitouPhone').val(),             //phoneNumber
        $('#manitouGlobalContainer #manitouResumeFileName').val(),    //resumeFileName
        $('#manitouGlobalContainer #manitouResumeFile')[0].files[0],  //resumeFile
        postingId,                                                    //postingId
        comment,                                                      //comment
        language                                                      //language
    )
    .done(function (data) {
        var attachmentToken = data.attachmentToken;
        var applicantId = data.applicantId;
        var applicationId = data.applicationId;

        var addAttachmentRequests = [];

        $('.manitouExtraAttachment').each(function (index, fileFieldElement) {
            var fileElement = fileFieldElement.querySelector('#manitouFile-' + index);
            var fileNameElement = fileFieldElement.querySelector('#manitouFileName-' + index);

            // Add attachments that are not empty
            if (fileElement.files[0]) {
                var request = services.postAddAttachment(
                    applicantId,                                      //applicantId 
                    applicationId,                                    //applicationId 
                    fileNameElement.value,                            //fileName 
                    fileElement.files[0],                             //file 
                    messages.services.platform + ' - ' + 
                    fileNameElement.getAttribute('placeholder'),      //description
                    fileElement.getAttribute('data-attachment-type'), //attachmentTypeId 
                    'N',                                              //isMainCV 
                    language,                                         //language 
                    attachmentToken                                   //tempToken
                )
                .fail(function (errorData) {
                    errorData.message;
                    $('#manitouGlobalContainer #manitouApplicationForm').append(templates.attachmentErrorMessage(fileNameElement.value));
                });

                addAttachmentRequests.push(request);
            }
        });

        $.when.apply(undefined, addAttachmentRequests).always(
            function () {
                displayApplicationSuccessMessage(data.message);
            }
        );
    })
    .fail(function (errorData) {
        //Remove preloader and display form again
        $('#manitouGlobalContainer #manitouApplicationForm .manitouPreloaderContainer').remove();
        $('#manitouGlobalContainer #manitouApplicationForm form').show();

        var fieldMatching = {
            'lastName': 'manitouLastName',
            'firstName': 'manitouFirstName',
            'email': 'manitouEmail',
            'phoneNumber': 'manitouPhone',
            'fileName': 'manitouResumeFileName',
            'file': 'manitouResumeFileName'
        }
        clearApplicationFormErrors();
        var genericError = false;
        $.each(errorData.errors, function (index, error) {
            var fieldId = fieldMatching[error.parameter];
            if (fieldId) {
                // Add errors that are related to a specific field under it
                var $field = $('#manitouGlobalContainer #' + fieldId); 
                $field.addClass('invalid');
                $field.closest('#manitouGlobalContainer .input-field').find('.helper-text').attr('data-error', error.message);
            } else {
                // Add errors that are not related to a specific field at the bottom of the form
                addApplicationFormError('• ' + error.message);
                genericError = true;
            }
        });
        if (genericError) {
            addApplicationFormError(messages.errors.genericTryAgain);
        }
    })
    .always(function () {
        //Enable form after loading
        $('#manitouGlobalContainer #manitouApplicationForm').removeClass('disabled');
    });
};

var validateApplicationForm = function () {
    var errorCount = 0;

    $('#manitouGlobalContainer #manitouApplicationForm .validate:visible').each(function (index, element) {
        if (!element.checkValidity()) {
            errorCount++;
            element.classList.add('invalid');
        }
    });

    $('#manitouGlobalContainer #manitouApplicationForm select[required]').each(function (index, element) {
        var $element = $(element);
        if ($element.parent().find('input').val() === '') {
            errorCount++;
            $element.closest('.select-wrapper').addClass('invalid');
        }
    });

    // Maximum file size validation (maximum file size is set in the POID_PJ Manitou system parameter)
    $('#manitouGlobalContainer .file-field').each(function(index, fileFieldElement){
        var fileHelperTextElement = fileFieldElement.querySelector('.helper-text');
        fileHelperTextElement.setAttribute('data-error', messages.validations.attachmentError);

        var attachmentFile = fileFieldElement.querySelector('input[type="file"]').files[0];
        var attachmentFileName = fileFieldElement.querySelector('input[type="text"]').value;

        if (attachmentFile) {
            if (validationParametersPromise) {
                validationParametersPromise.done(function(validationParameters) {
                    if (attachmentFile.size > validationParameters.maxFileSize) {
                        errorCount++;
                        fileHelperTextElement.setAttribute('data-error', 
                            messages.validations.attachmentMaxSizeError.replace(/{{maxsize}}/, validationParameters.maxFileSize)
                        );
                        fileFieldElement.querySelector('input.file-path').classList.add('invalid');
                    }
                });
            }
            if (attachmentFile.size <= 0) {
                errorCount++;
                fileHelperTextElement.setAttribute('data-error', 
                    messages.validations.attachmentEmptyError
                );
                fileFieldElement.querySelector('input.file-path').classList.add('invalid');
            }
            if (attachmentFileName.length <= 0) {
                errorCount++;
                fileHelperTextElement.setAttribute('data-error', 
                    messages.validations.attachmentNameEmptyError
                );
                fileFieldElement.querySelector('input.file-path').classList.add('invalid');
            }
            attachmentFile.size;
        }
    });

    return (errorCount == 0 ? true : false);
};

var clearApplicationFormErrors = function () {
    $('#manitouGlobalContainer #manitouApplicationFormErrors').html('');
};

var addApplicationFormError = function (errorMessage) {
    $('#manitouGlobalContainer #manitouApplicationFormErrors').append(templates.inlineError(errorMessage));
};

var displayApplicationSuccessMessage = function (successMessage) {
    var $form = $('#manitouGlobalContainer #manitouApplicationForm');
    $form.find('.manitouPreloaderContainer').hide();
    $form.find('form').hide();
    $form.addClass('success');
    $form.prepend(templates.successMessage(successMessage));
};

var displayCriticalError = function (errorMessage) {
    $('#manitouGlobalContainer').html(
        templates.errorMessage(errorMessage)
    );
};

var getExtraAttachments = function (configAttachmentsList, language) {
    var attachments = [];
    $.each(configAttachmentsList, function (index, attachment) {
        attachments.push({
            label: attachment.label[language],
            documentTypeId: attachment.documentTypeId,
            isRequired: attachment.isRequired
        });
    });

    return attachments;
};

var getSpontaneousApplicationCustomSelectionLists = function (configCustomSelectionLists, language) {
    var filteredSelectionLists = configCustomSelectionLists.filter(
        function(selectionList) {
            return selectionList.displayForSpontaneousApplicationForm
        }
    );
    return getCustomSelectionLists(filteredSelectionLists, language);
}

var getPostingApplicationCustomSelectionLists = function (configCustomSelectionLists, language) {
    var filteredSelectionLists = configCustomSelectionLists.filter(
        function(selectionList) {
            return selectionList.displayForPostingApplicationForm
        }
    );
    return getCustomSelectionLists(filteredSelectionLists, language);
}

var getCustomSelectionLists = function (configCustomSelectionLists, language) {
    var selectionLists = [];
    $.each(configCustomSelectionLists, function (index, selectionList) {
        selectionLists.push({
            label: selectionList.label[language],
            columnWidth: selectionList.columnWidth,
            isRequired: selectionList.isRequired,
            multipleSelections: selectionList.multipleSelections,
            otherManualOption: selectionList.otherManualOption,
            options: selectionList.options.map(
                function(option) {     
                    return {
                        label: option.label[language], 
                        value: option.value[language]
                    }
                })
        });
    });

    return selectionLists;
};